.privacy-page {
  padding-top: 50px;

  h1,
  h2,
  h3,
  h4,
  p,
  hr,
  li {
    color: rgb(216, 215, 215) !important;
  }

  h3 {
    margin: 20px 0 10px;
    font-weight: 400;
  }

  h4 {
    margin: 20px 0 10px;
    font-weight: 300;
  }

  li {
    list-style: circle;
    margin: 3px 0 3px 30px;
  }

  hr {
    margin: 10px 0 30px 0;
  }

  a.version {
    color: rgb(216, 215, 215) !important;
    text-decoration: underline;
    a:hover {
      color: rgb(198, 191, 255) !important;
      text-decoration: none;
    }
  }
}
